.cover-reports {
  background-color: var(--light-blue3);
  padding: 35px 55px;
  height: 100%;
}
.cover-reports .page-picker-containers {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
}
.cover-reports .page-picker-containers button {
  background-color: var(--tint-color);
  color: var(--light-text);
}
.cover-reports .page-title-text {
  margin-bottom: 27px;
  font-size: 25px;
  color: var(--grey-color2);
  font-weight: 600;
  padding-left: 0;
}
.search-button-container {
  display: flex;
  align-items: center;
}
.search-button-container button {
  background-color: var(--tint-color);
  color: var(--light-text);
  margin-left: 20px;
}
.cover-reports .filter-table-container {
  background-color: var(--bg-color);
  margin-top: 30px;
}
.cover-reports .filter-table-container.date-range {
  padding: 20px;
}
.cover-reports .filter-table-container .date-range-container {
  padding: 30px;
}
.cover-reports .loading-error:not(.no-data) {
  margin-top: 30px;
  margin-bottom: 0;
}
.date-range-select-title {
  margin: 0;
  color: var(--light-blue2);
  font-size: 16px;
  font-weight: 600;
}
.date-range-container-footer {
  padding: 25px 24px 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.date-range-container-footer button {
  background-color: var(--light-blue2);
  color: var(--light-text);
}
.date-range-container-footer button.back {
  margin-right: 10px;
}
.date-range-container-footer .footer-subtitle {
  font-size: 13px;
  margin-right: 15px;
}
.skip-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
}
.skip-buttons-container button:first-child {
  border: solid 1px var(--light-blue2);
  background-color: var(--bg-color);
  color: var(--light-blue2);
}
.skip-buttons-container button:last-child {
  background-color: var(--tint-color);
  color: var(--light-text);
  margin-left: 10px;
}
@media only screen and (max-width: 800px) {
  .cover-reports {
    padding: 10px 15px;
  }
  .cover-reports .page-picker-containers {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
  }
  .cover-reports .page-title-text {
    margin-bottom: 15px;
  }
  .cover-reports .filter-table-container.date-range {
    padding: 10px;
  }
  .cover-reports .filter-table-container .date-range-container {
    padding: 10px;
  }
  .date-range-select-title {
    margin: 10px;
  }
  .button-wrapper.disabled {
    grid-column-start: 2;
    grid-column-end: 3;
  }
  .skip-buttons-container {
    justify-content: center;
  }
  .overflow-chart-details {
    overflow-x: auto;
  }
}
@media only screen and (max-width: 600px) {
  .search-button-container button {
    margin-top: 15px;
    margin-left: 0;
  }
  .search-button-container {
    flex-direction: column;
  }
  .date-range-container-footer{
    justify-content: center;
  }
}
