.chips-list-container {
  display: flex;
  align-items: baseline;
}
.chips-container {
  margin-left: 18px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;
}
.chips-list-label {
  font-size: 13px;
  font-weight: bold;
  color: var(--grey-color2);
  margin-top: 15px;
  white-space: nowrap;
}
.chips-container .show-all-button {
  font-size: 13px;
  color: var(--light-blue2);
  text-decoration: underline;
  cursor: pointer;
  margin-top: 15px;
  white-space: nowrap;
}
@media only screen and (max-width: 800px) {
  .chips-list-container {
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-word;
    flex-direction: column;
    align-items: flex-start;
  }
  .chips-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }
}
