.filter-table-container.result-chart .date-range-container-footer {
    padding: 25px 45px;
    border-top: 1px solid var(--grey-color3);
}
.filter-table-container.result-chart .date-range-select-title {
    padding: 20px 20px 0;
}
.report-chart-wrapper {
    padding: 50px 20px;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
}
.report-chart-wrapper .report-chart-filters {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.report-chart-wrapper .report-chart-filters .report-chart-filters-col,
.report-chart-filters .report-chart-filters-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.report-chart-wrapper .report-chart-filters .report-chart-filters-col .title {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
}
.report-chart-wrapper .report-chart-filters .report-chart-filters-col .subtitle {
    font-size: 12px;
}
.report-chart-filters-info .subtitle.percentage {
    margin-bottom: 10px;
}
.report-chart-filters-info .subtitle.date {
    color: var(--color-red)
}
.report-chart-filters-info .subtitle:last-child {
    margin-top: 10px;
    font-weight: 600;
}
.report-chart-wrapper .report-chart-filters .rounded-div {
    margin-right: 10px;
    background-color: var(--color-red);
}
@media only screen and (max-width: 950px) {
    .report-chart-wrapper{
      align-items: center;
      flex-direction: column;
      padding: 50px 30px;
    }
    .report-chart-wrapper .report-chart-filters{
      margin-top: 40px;
      width: 100%;
      justify-content: center;
    }
}