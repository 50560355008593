:root{
    --tint-color: #ffab1d;
    --bg-color: #ffffff;
    --light-text: #ffffff;
    --dark-text: #000000;
    --shadow-color: rgba(0, 0, 0, 0.2);
    --grey-color1: #7f7f7f;
    --grey-color2: #202020;
    --grey-color3: #dddddd;
    --grey-color4: #8991b1;
    --light-blue: #7d86a9;
    --light-blue2: #4051b5;
    --light-blue3: #f1f6ff;
    --light-blue4: #e5e8eb;
    --light-blue5: #d6daef;
    --light-blue-op: rgba(64, 81, 181, 0.15);
    --light-blue-op2: rgba(125, 134, 169, 0.26);
    --color-blue: #1676ff;
    --color-yellow: #ffdf14;
    --color-green: #51c03e;
    --color-red: #e52f7b;
}