.loading-error.noText{
    padding: 19px 0;
}
.loading-error{
    width: 100%;
    padding: 55px 0;
    text-align: center;
    font-size: 15px;
    color: var(--shared-error-color);
}
.loading-error.no-data{
    color: var(--shared-grey-color3);
}