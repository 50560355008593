.select-wrapper{
    position: relative;
}
.select-input{
    font-size: 12px;
    font-weight: 600;
    padding: 7px 5px 7px 15px;
    max-width: 250px;
    width: auto;
    border: solid 2px var(--light-blue4);
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    z-index: 1;
}
.truncate {
  white-space: nowrap; 
  width: 110px; 
  overflow: hidden;
  text-overflow: ellipsis; 
}
.animate {
    -moz-transition: all .5s;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.select-input-opt-container p{
    color: var(--dark-text);
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    width: 97%;
}
.select-input.placeholder{
    color: var(--light-blue);
}
.select-input p{
    width: 88%;
    margin: 0;
}
.select-input svg {
    margin-right: 10px;
    font-size: 16px;
}
.rotate {
    transform: rotate(180deg);
}
.select-input-opt-container{
    border: solid 2px var(--light-blue4);
    border-top: none;
    border-radius: 5px;
    width: fit-content;
    max-height: 150px;
    overflow-y: overlay;
    position: absolute;
    z-index: 2;
    overflow-x: hidden;
    background-color: var(--bg-color);
    box-shadow: 1px 3px 20px -11px var(--shadow-color);
}
.select-input-opt-container .select-input-opt{
    padding: 7px 5px 7px 15px;
    width: 225px;
    cursor: pointer;
}
.select-input-opt-container .select-input-opt:hover{
    background-color: var(--light-blue5);
}
@media only screen and (max-width: 800px) {
    .select-input-opt-container .select-input-opt{
        width: 200px;
    }
}