.table-container button {
  background-color: var(--light-blue2);
  color: var(--light-text);
}
.table-container .footer-container button.back{
  margin-right: 10px;
}
.table-container button.disabled {
  opacity: 0.4;
}
.table-container .data-table-container button {
  padding: 5px 14px;
  font-size: 13px;
}
.table-title {
  padding: 20px 22px;
  color: var(--light-blue2);
  font-size: 16px;
  font-weight: 600;
}
.table-title span {
  font-size: 12px;
}
.table-title p {
  font-size: 14px;
  margin: 0;
}
.colums-title-container {
  display: flex;
  flex-direction: row;
  border-top: 1px solid var(--grey-color3);
  border-bottom: 1px solid var(--grey-color3);
}
.colums-title-container {
  font-size: 13px;
  font-weight: bold;
  padding: 20px 44px;
  width: calc(100% - 88px);
}
.row-table-container {
  font-size: 13px;
  padding: 20px 44px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--grey-color3);
  width: calc(100% - 88px);
}
.rounded-div {
  width: 13px;
  height: 13px;
  border-radius: 100px;
  margin-right: 18px;
}
.status-round {
  display: flex;
  align-items: center;
}
.status-round.YELLOW .rounded-div {
  background-color: var(--color-yellow);
}
.status-round.RED span {
  color: var(--color-red);
}
.status-round.RED .rounded-div {
  background-color: var(--color-red);
}
.status-round.GREEN .rounded-div {
  background-color: var(--color-green);
}
.status-round.UNKNOWN .rounded-div {
  background-color: var(--grey-color1);
}
.colum-title-label,
.data-table-container {
  width: inherit;
  align-items: center;
  display: flex;
}
.colum-title-label:last-child,
.data-table-container:last-child {
  display: flex;
  justify-content: flex-end;
}
.footer-container {
  padding: 25px 44px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid var(--grey-color3);
}
.footer-container.no-padding {
  padding: 0;
}
.body-table-container {
  max-height: 350px;
  overflow-y: overlay;
}
.body-table-container .row-table-container:last-child {
  border-bottom: none;
}
@media only screen and (max-width: 800px) {
  .colum-title-label,
  .data-table-container {
    word-break: break-word;
  }
  .colum-title-label:not(:last-child) span,
  .data-table-container {
    width: 85%;
  }
  .row-table-container {
    padding: 15px 25px;
    width: calc(100% - 50px);
    align-items: center;
  }
  .colums-title-container {
    padding: 20px 25px;
    width: calc(100% - 50px);
    align-items: center;
  }
  .status-round {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
  }
  .rounded-div {
    margin-right: 0;
  }
}
@media only screen and (max-width: 600px) {
  .footer-container{
    justify-content: center;
  }
}