.page-title-text{
    color: var(--shared-tint-color);
    font-size: 18px;
    font-weight: bold;
    padding-left: 10px;
}
@media only screen and (max-width: 800px) {
    .page-title-text{
        font-size: 18px;
        padding-left: 0;
        margin-bottom: 15px;
    }
}