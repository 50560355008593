.report-chart-container{
    width: 100%;
    max-width: 440px;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media only screen and (max-width: 950px) {
    .report-chart-container{
        max-width: 80%;
    }
}
@media only screen and (max-width: 600px) {
    .report-chart-container{
        max-width: 100%;
    }
}