.tabs-list-header{
    display: flex;
    align-items: center;
}
.tabs-list-option:last-child{
    margin: 0;
}
@media only screen and (max-width: 767px) {
    .tabs-list-option {
        padding: 10px;
        font-size: 11px;
    }
}