.chip-wrapper {
  display: flex;
  flex-direction: row-reverse;
  background-color: var(--light-blue-op);
  width: fit-content;
  border-radius: 3px;
  padding: 10px;
  align-items: center;
  line-height: 1;
  cursor: pointer;
  margin: 15px 11px 0 0;
}
.chip-wrapper span {
  font-size: 13px;
  color: var(--grey-color2);
  font-weight: 500;
  white-space: nowrap;
}
.chip-wrapper .chip-cancel {
  display: flex;
  align-items: center;
  border-radius: 100px;
  width: 11px;
  height: 11px;
  color: var(--light-blue);
  margin-right: 13px;
  border: 1px solid var(--light-blue);
}
@media only screen and (max-width: 800px) {
  .chip-wrapper {
    margin-top: 15px;
    margin-left: 0;
  }
  .chip-wrapper:first-child {
    margin-top: 15px;
  }
}
