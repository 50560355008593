.date-range-container {
  display: flex;
  align-items: flex-end;
}
.date-range-container .chips-list-label {
  margin-top: 0;
  margin-right: 28px;
  padding-bottom: 3px;
}
.date-range-container input {
  border: unset;
  color: var(--grey-color2);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 0;
}
.date-labels-container input{
  width: 180px;
}
.calendar-container {
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--light-blue-op2);
}
.calendar-container svg {
  color: var(--light-blue);
}
.labels-container {
  font-size: 7px;
  color: var(--light-blue);
}
.labels-container span:first-child {
  margin-right: 30%;
}
.rdrCalendarWrapper.rdrDateRangeWrapper.calendarElement {
  position: absolute;
  box-shadow: 0 0 10px var(--shadow-color);
  margin-top: -430px;
  border-radius: 3px;
}
@media only screen and (max-width: 800px) {
  .rdrCalendarWrapper.rdrDateRangeWrapper.calendarElement {
    position: absolute;
    box-shadow: 0 0 10px var(--shadow-color);
    border-radius: 3px;
    left: 5%;
    height: 390px;
  }
}
@media only screen and (max-width: 420px) {
  .date-range-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .date-range-container .chips-list-label{
    margin-bottom: 10px;
  }
}
