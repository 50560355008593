.filter-selected-container .final-filter {
    padding: 8px 10px;
    background-color: #fff;
    font-size: 12px;
    display: flex;
    max-width: 200px;
    margin-right: 10px;
    font-weight: 600;
    margin-bottom: 10px;
}
.filter-selected-container .final-filter span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-word;
}
.filter-selected-container {
    margin-top: 10px;
}
.filter-selected-container.no-edit {
    margin-top: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
@media only screen and (max-width: 540px) {
    .filter-selected-container.no-edit {
      display: grid;
      grid-template-columns: 175px 175px;
    }
}