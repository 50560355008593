.button-wrapper{
    padding: 8px 30px;
    border-radius: 100px;
    border: none;
    cursor: pointer;
    -moz-transition: all .5s;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    background-color: var(--shared-grey-color1);
    color: var(--shared-grey-color2);
    border-radius: 100px;
    padding: 8px 30px;
}
.button-wrapper.disabled {
   pointer-events: none;
   opacity: .7;
}
.button-wrapper.back {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
}
.button-wrapper svg {
    margin-right: 5px;
    font-size: 20px;
}
.button-wrapper.halfLeft {
    background-color: var(--shared-grey-color1);
    color: var(--shared-grey-color2);
    border-radius: 0 100px 100px 0;
    padding: 11px 25px;
}
.button-wrapper:hover {
    opacity: 0.8;
}