.search-container {
  display: flex;
  align-items: center;
  background-color: var(--bg-color);
  border-radius: 3px;
  width: fit-content;
  padding-right: 20px;
}
.search-label-container {
  padding: 10px 27px 10px 22px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
}
.divider {
  height: 23px;
  border-left: 1px solid var(--grey-color4);
}
.search-wrapper {
  display: flex;
  align-items: center;
  height: 34px;
  padding-left: 22px;
}
.search-wrapper svg {
  color: var(--light-blue);
}
.search-wrapper input {
  border-radius: 0;
  outline: none;
  border: 0;
  box-shadow: none;
  height: 100%;
  margin-left: 14px;
  font-size: 12px;
  color: var(--grey-color2);
  font-weight: bold;
  width: 100px;
}
.search-cancel {
  display: flex;
  align-items: center;
  border-radius: 100px;
  width: 11px;
  height: 11px;
  color: var(--light-blue);
  border: 1px solid var(--light-blue);
  margin-right: 3px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 800px) {
  .search-container {
    grid-column-start: 1;
    grid-column-end: 4;
  }
}
