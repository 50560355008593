.checkbox-container {
    display: flex;
    align-items: center;
    width: fit-content;
}
.checkbox-container span {
    margin-left: 5px;
    color: var(--grey-color2);
    font-size: 13px;
    font-weight: 600;
}