.tabs-list-option{
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--light-blue);
    padding: 13px 16px;
    margin-right: 30px;
    font-size: 12px;
    font-weight: 400;
    position: relative;
    cursor: pointer;
    text-align: center;
}
.tabs-list-body{
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--grey-color3);
    padding: 40px 0;
}
.tabs-list-option.selected{
    color: var(--dark-text);
    font-weight: bold;
    border-bottom: 3px solid var(--color-blue);
}
@media only screen and (max-width: 800px) {
    .tabs-list-option{
        margin-right: 0;
        width: -webkit-fill-available;
        width: fill-available;
    }
}