.loading-container {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7.5px 0;
}
.loading-container button {
    margin: 0 !important;
}
.loading-container.full {
    width: 100%;
    justify-content: center;
    padding: 50px 0;
}
.loading-container svg {
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    color: var(--shared-tint-color);
    font-size: 25px;
    margin-right: 20px;
}
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .loading-container.full svg {
    font-size: 60px;
    margin-right: 0;
  }